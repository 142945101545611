import type { Language } from '~/ts/types/language'
import type { LangT } from '~/helpers/languageTools'
import { useLanguageStore } from '~/stores/language'
import { langToolT } from '~/helpers/languageTools'
import { getPreferredLanguagesFromSSRContext } from '~/helpers/getPreferredLanguagesFromSSRContext'
import uk from '~/locales/uk.json'
import en from '~/locales/en.json'
import ru from '~/locales/ru.json'

type Injections = {
    t: LangT
}

declare module '#app' {
    interface NuxtApp {
        $t: Injections['t']
    }
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $t: Injections['t']
    }
}

export default defineNuxtPlugin((nuxtApp): { provide: Injections } => {
    if (useError().value) {
        return { provide: { t: (key: string): string => key } }
    }

    const languageStore = useLanguageStore()

    if (import.meta.server) {
        const { isSupportedLanguage, setLanguage, setCookieLanguage } = languageStore
        const { currentRoute } = useRouter()
        const routeLang = String(currentRoute.value.params.language || '') as Language
        const cookieLang = useCookie<Language>('language').value as Language
        const preferredLanguage = routeLang || cookieLang

        if (preferredLanguage && isSupportedLanguage(preferredLanguage)) {
            if (languageStore.language !== preferredLanguage) {
                setLanguage(preferredLanguage)
            } else if (cookieLang !== preferredLanguage) {
                setCookieLanguage(preferredLanguage)
            }
        } else {
            const preferredLanguages = getPreferredLanguagesFromSSRContext(nuxtApp.ssrContext) as Language[]

            for (const lang of preferredLanguages) {
                if (isSupportedLanguage(lang)) {
                    setLanguage(lang)

                    break
                }
            }
        }
    }

    const locales = { uk, en, ru }

    return {
        provide: {
            t: langToolT.bind(this, languageStore, locales),
        },
    }
})
