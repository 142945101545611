export const appLayoutTransition = false

export const appPageTransition = false

export const appHead = {"meta":[{"name":"viewport","content":"viewport-fit=cover, width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no"},{"charset":"utf-8"}],"link":[{"rel":"preload","href":"/fonts/TTNormsPro-Bold.woff2","type":"font/woff2","as":"font","fetchpriority":"high","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/TTNormsPro-Medium.woff2","type":"font/woff2","as":"font","fetchpriority":"high","crossorigin":"anonymous"},{"rel":"preload","href":"/fonts/TTNormsPro-Regular.woff2","type":"font/woff2","as":"font","fetchpriority":"high","crossorigin":"anonymous"},{"rel":"stylesheet","href":"/css/font.css","type":"text/css","fetchpriority":"high","crossorigin":"anonymous"},{"rel":"icon","type":"image/x-icon","href":"/favicon/default/favicon.ico"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon/default/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon/default/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"/favicon/default/favicon-192x192.png"},{"rel":"icon","type":"image/png","sizes":"512x512","href":"/favicon/default/favicon-512x512.png"},{"rel":"apple-touch-icon","type":"image/png","href":"/favicon/default/apple-touch-icon.png"}],"style":[],"script":[],"noscript":[{"innerHTML":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-WB98V45P\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>"}],"charset":"utf-8","viewport":"viewport-fit=cover, width=device-width, height=device-height, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no","bodyAttrs":{"ontouchstart":""}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"