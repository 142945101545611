<script
    lang="ts"
    setup
>
    import { useLanguageStore } from '~/stores/language'

    const { public: { isProd, appName, siteUrl } } = useRuntimeConfig()
    const { mobile, tablet, desktop, maxTablet, maxDesktop } = useWindowSize()
    const { t } = useLang()

    const languageStore = useLanguageStore()

    const seoMetaDescription = computed<string>(() => t('seo-meta-description'))
    const seoMetaImgUrl = computed<string>(() => `${ siteUrl }/img/seo-meta-${ languageStore.language }.png`)

    useServerSeoMeta({
        robots: isProd ? 'index, follow' : 'noindex, nofollow',
        themeColor: '#f6f7f8',
        title: appName,

        ogTitle: appName,
        ogUrl: siteUrl,
        ogImageAlt: appName,

        twitterTitle: appName,
        twitterSite: siteUrl,
        twitterCard: 'summary_large_image',
        twitterImageAlt: appName,
    })

    useSeoMeta({
        description: seoMetaDescription,

        ogDescription: seoMetaDescription,
        ogImage: seoMetaImgUrl,

        twitterDescription: seoMetaDescription,
        twitterImage: seoMetaImgUrl,

        titleTemplate(titleChunk: string) {
            if (!titleChunk || (titleChunk === appName)) {
                return t('seo-meta-title', appName)
            }

            if (titleChunk) {
                return `${ titleChunk } — ${ appName }`
            }
        },
    })
</script>

<template>
    <NuxtLayout>
        

        <NuxtPage />
    </NuxtLayout>
</template>
