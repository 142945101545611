import revive_payload_client_OOHdaehMns from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__uqqksdsktxrdk6etpo3forjzvm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_T8aCCfTgLd from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__uqqksdsktxrdk6etpo3forjzvm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_IIWt7NSYtV from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__uqqksdsktxrdk6etpo3forjzvm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_PEqJfYaDhd from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@1.79._rwms27gosf6s7llnlut5jgbojq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_omcvfUIMsJ from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__uqqksdsktxrdk6etpo3forjzvm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_6iYLSC1dxt from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__uqqksdsktxrdk6etpo3forjzvm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_5TgwrDjnYH from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__uqqksdsktxrdk6etpo3forjzvm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6WE5FXu4tx from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__uqqksdsktxrdk6etpo3forjzvm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_E4JHtm2uoD from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/ilya/Sites/spilky-landing/.nuxt/components.plugin.mjs";
import prefetch_client_Emr9CK0but from "/Users/ilya/Sites/spilky-landing/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__uqqksdsktxrdk6etpo3forjzvm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _01_localization_Sjiymv6NFV from "/Users/ilya/Sites/spilky-landing/plugins/01.localization.ts";
import _02_widget_client_yEJB2c6tIw from "/Users/ilya/Sites/spilky-landing/plugins/02.widget.client.ts";
import _03_gtag_client_UmwxH6v3pj from "/Users/ilya/Sites/spilky-landing/plugins/03.gtag.client.ts";
import _04_popupManager_2RFfGIF6ES from "/Users/ilya/Sites/spilky-landing/plugins/04.popupManager.ts";
export default [
  revive_payload_client_OOHdaehMns,
  unhead_T8aCCfTgLd,
  router_IIWt7NSYtV,
  _0_siteConfig_PEqJfYaDhd,
  payload_client_omcvfUIMsJ,
  navigation_repaint_client_6iYLSC1dxt,
  check_outdated_build_client_5TgwrDjnYH,
  chunk_reload_client_6WE5FXu4tx,
  plugin_vue3_E4JHtm2uoD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Emr9CK0but,
  _01_localization_Sjiymv6NFV,
  _02_widget_client_yEJB2c6tIw,
  _03_gtag_client_UmwxH6v3pj,
  _04_popupManager_2RFfGIF6ES
]