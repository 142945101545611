import { defineStore } from 'pinia'
import type { Language, LanguageLocale } from '~/ts/types/language'
import type { FormSelectOption } from '~/ts/types/form'
import { DEFAULT_COOKIE_OPTIONS } from '~/constants/cookie'

export const useLanguageStore = defineStore('languageStore', () => {
    const defaultLanguage: Language = 'uk'
    const supportedLocales: LanguageLocale[] = [
        {
            name: 'Українська',
            iso: 'uk',
            flag: '🇺🇦',
            nativeLocale: 'uk-UA',
        },
        {
            name: 'English',
            iso: 'en',
            flag: '🇺🇸',
            nativeLocale: 'en-US',
        },
        {
            name: 'Ру**кий',
            iso: 'ru',
            flag: '🏳️',
            nativeLocale: 'ru-RU',
        },
    ]
    const { currentRoute } = useRouter()

    const language = computed<Language>(() => (currentRoute.value.params.language || defaultLanguage) as any)
    const locale = computed<LanguageLocale>(() => supportedLocales.find(v => v.iso === language.value))
    const supportedLanguages = computed<Language[]>(() => supportedLocales.map(v => v.iso))
    const languageOptions = computed<FormSelectOption[]>(() => {
        return supportedLocales.map(v => ({
            value: v.iso,
            text: v.name,
        }))
    })
    const languageOptionsByValue = computed<Record<Language, FormSelectOption>>(() => {
        const options = {}

        for (const option of languageOptions.value) {
            options[option.value] = option
        }

        return options as Record<Language, FormSelectOption>
    })
    const supportedLocalesByIso = computed<Record<LanguageLocale['iso'], LanguageLocale>>(() => {
        const locales = {}

        for (const option of supportedLocales) {
            locales[option.iso] = option
        }

        return locales as Record<LanguageLocale['iso'], LanguageLocale>
    })

    const isSupportedLanguage = (language: Language): boolean => supportedLanguages.value.includes(language)

    const setCookieLanguage = (value: Language): void => {
        useCookie('language', DEFAULT_COOKIE_OPTIONS).value = value
    }

    const setLanguage = (value: Language): void => {
        if (!isSupportedLanguage(value)) {
            value = defaultLanguage
        }

        if (language.value === value) {
            return
        }

        setCookieLanguage(value)

        navigateTo({
            params: {
                language: (value === defaultLanguage ? '' : value),
            },
        })
    }

    return {
        defaultLanguage,
        supportedLocales,
        supportedLocalesByIso,
        language,
        locale,
        supportedLanguages,
        languageOptions,
        languageOptionsByValue,
        isSupportedLanguage,
        setLanguage,
        setCookieLanguage,
    }
})
