import type { Language, LanguageLocale } from '~/ts/types/language'
import type { LangT, LangTC } from '~/helpers/languageTools'
import { useLanguageStore } from '~/stores/language'
import { langToolT, langToolTC } from '~/helpers/languageTools'
import uk from '~/locales/uk.json'
import en from '~/locales/en.json'
import ru from '~/locales/ru.json'

export type UseLang = {
    t: LangT
    tc: LangTC
    defaultLanguage: Language
    language: Language
    locale: LanguageLocale
    supportedLanguages: Language[]
    supportedLocales: LanguageLocale[]
    isSupportedLanguage: (language: Language) => boolean
    setLanguage: (language: Language) => void
}

export const useLang = (): UseLang => {
    const languageStore = useLanguageStore()

    const locales = { uk, en, ru }

    return {
        t: langToolT.bind(this, languageStore, locales),
        tc: langToolTC.bind(this, languageStore, locales),
        language: languageStore.language,
        locale: languageStore.locale,
        defaultLanguage: languageStore.defaultLanguage,
        supportedLanguages: languageStore.supportedLanguages,
        supportedLocales: languageStore.supportedLocales,
        isSupportedLanguage: languageStore.isSupportedLanguage,
        setLanguage: languageStore.setLanguage,
    }
}
